import {defineComponent, computed, watch, onBeforeMount, ref, onMounted, h} from "vue"
import DummyBoard from "@/views/Dummy/DummyScreen/DummyBoard.vue";
import {useAppUtils} from "@/composables/useAppUtils"
// import SvgIcon from "@/components/SvgIcon";
import VMenuSeparator from '@/components/Menus/VMenuSeparator.vue'
import VMenuItem from '@/components/Menus/VMenuItem.vue'
import TestComponentTwo from '@/components/TestComponent.vue'


const testComponent = h({
  template: `<div>
@@@123###
    <ul>
    <v-menu-item>
      q
    </v-menu-item>
    <v-menu-item>
      w
    </v-menu-item>
    <v-menu-separator></v-menu-separator>
    <v-menu-item>
      e
    </v-menu-item>
  </ul>

</div>`,
  props: {},
  computed: {},
  components: {
    VMenuSeparator,
    VMenuItem,

  },
  provide: {},
}, {})

export default defineComponent({
  name: "DummyScreen",
  components: {
    DummyBoard,
    testComponent,
    TestComponentTwo,
    VMenuSeparator,
    VMenuItem,
    // SvgIcon
  },
  props: {
    userIdFromRoute: {
      type: String,
      required: false
    },
    serverParam: {
      type: String,
      required: false
    },
  },

  setup(props/*, context*/) {
    const c = ref(0);
    const serverTemplate = ref('test')


    const inc = ()=>{
      c.value = c.value + 10;
    }

    const {
      hidePanelsLoaders,
      hideStartLoader,
    } = useAppUtils()

    onMounted(async () => {
      console.log('Dummy onMounted')
      await hidePanelsLoaders()
      hideStartLoader()
      setTimeout(() => {
        serverTemplate.value = `<div>
received from server
    <ul>
    <v-menu-item>
      <span @click="hello(5)">{{ t('Edit') }}</span>
    </v-menu-item>
    <v-menu-item>
      w
    </v-menu-item>
    <v-menu-separator></v-menu-separator>
    <v-menu-item>
      e
    </v-menu-item>
  </ul>

</div>`
      }, 2000)
    })

    return {
      c,
      inc,
      serverTemplate
    }
  },

});

