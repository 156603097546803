<template>
  <div class="main-container with-nav overflow-hidden">
    <div class="toolbar  flex gap-20" style="width: 700px">
      11111
    </div>
    <div>
      ={{c}}
      <dummy-board :c="c" @inc="inc"></dummy-board>
    </div>
    <p>
      <test-component></test-component>
    </p>
    <p>
      <TestComponentTwo :content="serverTemplate" :price="88"></TestComponentTwo>
    </p>
  </div>
</template>

<script src="./DummyScreen.ts"></script>
<style lang="sass" scoped src="./DummyScreen.sass"></style>
