
import {defineComponent, nextTick, onMounted, ref, toRefs} from "vue";

export default defineComponent({
  name: "DummyBoard",
  props: {
    c: Number
  },
  emits: [
    'inc'
  ],

  setup(props, {emit}) {
    const steps = ref<{ id: string }[]>([]);
    const c = ref(1);

    const fill = function () {
      let _steps: { id: string }[] | null = [];
      for (let i = c.value!; i < (c.value! + 10); i++) {
        _steps.push({id: 's_' + i})
      }
      steps.value = _steps;
      _steps = null;
    }

    onMounted(() => {
      nextTick(() => {
        // fill();
      })
    });

    const clear = function () {
      steps.value = [];
    }

    const clearClick = () => {
      clear();
    }
    const fillClick = () => {
      fill();
    }

    fill();

    return {
      steps,
      clearClick,
      fillClick
    }
  },

});
