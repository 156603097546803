<script>
  // https://stackoverflow.com/questions/76087375/vue-3-h-with-custom-component/76136369#76136369
  /*
  Needs runtimeCompiler: true in vue.config.js
   */
  import { h } from 'vue';
  import VMenuSeparator from '@/components/Menus/VMenuSeparator.vue'
  import VMenuItem from '@/components/Menus/VMenuItem.vue'
  import {useI18n} from "vue-i18n"

  export default {
    props: {
      content: {
        type: String,
        default: '',
      },
      price: {
        type: Number,
        default: 0,
      },
    },
    render() {

      const { t } = useI18n()

      const r = {
        props: {
          price: {
            type: Number,
            default: 0,
          },
        },
        components: {
          // AppAlert,
          VMenuItem,
          VMenuSeparator,
        },
        template: `<div class="content"> {{price}} | ${this.content || ''}</div>`,
        methods: {
          t,
          hello(number) {
            // method "hello" is also available here
            alert('hello ' + number)
          },
        },
      };
      return h(r, this.$props);
    },
  };
</script>
